<template>
  <i-modal :title="$t(advisorDataObj.id > 0 ? 'Edit Advisor' : 'Add Advisor')" @modal-close="modalClose">
    <form-group>
      <row>
        <column md="6" class="mb-3 mb-md-0">
          <label class="form-label" for="adv_f_name">{{ $t('First name') }} *</label>
          <b-input type="text" id="adv_f_name" v-model="advisorDataObj.first_name"/>
        </column>
        <column md="6" class="mb-3 mb-md-0">
          <label class="form-label" for="adv_l_name">{{ $t('First name') }} *</label>
          <b-input type="text" id="adv_l_name" v-model="advisorDataObj.last_name"/>
        </column>
      </row>
    </form-group>
    <form-group>
      <row>
        <column md="6" class="mb-3 mb-md-0">
          <label class="form-label" for="adv_email">{{ $t('Email') }} *</label>
          <b-input type="text" id="adv_email" v-model="advisorDataObj.email"/>
        </column>
        <column md="6" class="mb-3 mb-md-0">
          <label class="form-label" for="adv_phone_no">{{ $t('Phone number') }} *</label>
          <b-input type="text" id="adv_phone_no" v-model="advisorDataObj.phone_number"/>
        </column>
      </row>
    </form-group>
    <form-group>
      <row>
        <column md="6" class="mb-3 mb-md-0">
          <row>
            <column lg="6">
              <label class="form-label" for="adv_postcode">Postcode *</label>
              <b-input type="text" id="adv_postcode" placeholder="5542 AW" v-model="advisorDataObj.post_code"/>
            </column>
            <column lg="6">
              <label class="form-label" for="adv_house_no">{{ $t('House number') }} *</label>
              <b-input type="text" id="adv_house_no" v-model="advisorDataObj.house_no"/>
            </column>
          </row>
        </column>
        <column md="6">
          <label class="form-label" for="adv_lang">{{$t('Language')}}</label>
          <b-select id="lang" v-model="advisorDataObj.lang">
            <option value="nl">{{$t('Dutch')}}</option>
            <option value="en">{{$t('English')}}</option>
          </b-select>
        </column>
      </row>
    </form-group>
    <template v-slot:footer class="p-5">
      <nk-button type="primary" class="mr-2" v-on:click="addUpdateAdvisor">{{ $t(advisorDataObj.id > 0 ? 'Update' : 'Add') }}</nk-button>
      <a href="javascript:;" class="link link-light" v-on:click="modalClose">{{ $t('Cancel') }}</a>
    </template>
  </i-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import FormGroup from "@core/layouts/form-group/FormGroup";
import Column from "@core/layouts/col/Col";
import BInput from "@core/components/bp-form/components/BInput";
import Row from "@core/layouts/row/Row";
import {reactive} from "vue";
import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import BSelect from "@core/components/bp-form/components/BSelect";
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'
export default {
  components:{BSelect, Row, BInput, Column, FormGroup, IModal},
  props:{
    advisorData: null,
    companyId: null,
  },
  emits: ['modalClose', 'success'],
  setup(props, {emit}){

    const{t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const{endPointByRole, userRole} = useStoreHelper()
    let advisorDataObj = reactive({
      email: props.advisorData?.email,
      first_name: props.advisorData?.first_name,
      house_no: props.advisorData?.house_no,
      id: props.advisorData?.id || 0,
      lang: props.advisorData?.lang,
      last_name: props.advisorData?.last_name,
      phone_number: props.advisorData?.phone_number,
      post_code: props.advisorData?.post_code,
    })

    const addUpdateAdvisor = async () => {

      let fd = Object.assign({}, advisorDataObj)
      let email_reg = /^\S+@\S+$/;
      if( !fd.first_name || !fd.last_name || !fd.post_code || !fd.house_no || !fd.email){
        toastController.create({color: 'danger', message: t('errors.please_fill_fields_with_asterisk'), duration:2000, position: 'top'}).then( toast => toast.present())
        return
      }
      else if( !email_reg.test( advisorDataObj.email)){
        toastController.create({color: 'danger', message: t('registration.provide_valid_email'), duration:2000, position: 'top'}).then( toast => toast.present())
        return
      }
      fd.company_id = props.companyId

      let loader = await loadingController.create({message: t('Please wait...')})
      await loader.present()

      const alertB = await alertController.create({
        header: t('Error'),
        cssClass: isDesktop ? 'alert-web' : '',
        buttons: [{text: t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
      });
      let endpointExtras = userRole.value === 'is-admin' ? '/company' : ''

      axios.post(endPointByRole+endpointExtras+'/advisor/set', fd)
          .then(r=>{
            if(!r.data.success){
              alertB.message = r.data.message
              alertB.present()
            }
            else{
              modalClose()
              let msg = t('thank_you_messages.advisor_added_updated')
              if( advisorDataObj.id < 1){
                msg += "<br>"+t('account_activation_mail_sent')
              }

              emit('success', {data: r.data.data[0], action: advisorDataObj.id < 1 ? 'added' : 'updated'})

              alertController.create({
                header: t('Success'),
                cssClass: isDesktop ? 'alert-web' : '',
                message: msg,
                buttons:[{text: t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
              }).then((al) => al.present())
            }
          })
          .catch(er=>{
            alertB.message = er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error')
            alertB.present()
          })
          .then(()=> loader.dismiss());
    }

    const modalClose = () => {
      emit('modalClose')
    }

    return{
      addUpdateAdvisor,
      advisorDataObj,
      modalClose,
    }
  }
}
</script>

<style scoped>

</style>