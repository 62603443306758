<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Advisors')" :description="$t('data_count', {count: pagination.totalRecords, type: $t('advisors')})">
        <block-tools>
          <li v-if="userRole === 'is-company'">
            <nk-button type="primary" v-on:click="[advisorAddEditForm.modalOpen=true]">
              <nio-icon icon="ni-plus"></nio-icon> {{$t('Add Advisor')}}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control w-100" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="email">{{ $t('Send Email') }}</option>
                        <option value="suspend">{{ $t('Suspend')+' '+$t('User') }}</option>
                        <option value="delete">{{ $t('Delete User') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== '' || mandateFilter !== '' ? 'primary':'')"
                            :title="$t('Advisors')+' '+$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6" v-if="userRole === 'is-admin'">
                                <label class="overline-title overline-title-alt" for="mandate_filter">{{ $t('Mandate') }}</label>
                                <select class="form-control form-control-sm" id="mandate_filter" v-model="mandateFilter">
                                  <option value="">{{ $t('All') }}</option>
                                  <option value="1">{{ $t('Signed') }}</option>
                                  <option value="0">{{ $t('Not Signed') }}</option>
                                </select>
                              </column>
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="status_filter">{{ $t('Account status') }}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="">{{ $t('All') }}</option>
                                  <option value="1">{{ $t('Active')}}</option>
                                  <option value="0">{{ $t('Disabled') }}</option>
                                </select>
                              </column>
                            </row>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="active_status_filter">{{ $t('Active Status') }}</label>
                                <select class="form-control form-control-sm" id="active_status_filter" v-model="activeStatusFilter">
                                  <option value="">{{ $t('All') }}</option>
                                  <option value="0">{{ $t('Inactive')}}</option>
                                  <option value="1">{{ $t('Active') }}</option>
                                  <option value="2">{{ $t('On hold') }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getAdvisors(true)">{{ $t('Filter') }}</nk-button>
                            <a href="javascript:;" v-on:click="[statusFilter=activeStatusFilter=mandateFilter='',getAdvisors(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getAdvisors(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getAdvisors(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')+' '+$t('Advisor')"
                  :is-active="searchBarActive"
                  @do-search="searchAdvisors"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchAdvisors('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="adv_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">
                      <span class="sub-text">ID</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{ $t('User') }}</span>
                    </table-col>
                    <table-col break-point="md">{{ $t('Active Status') }}</table-col>
                    <table-col break-point="md">
                      <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;" v-tooltip="$t('Notes')"></nio-icon>
                    </table-col>
                    <table-col break-point="mb">{{ $t('City') }}</table-col>
                    <table-col break-point="md">Leads</table-col>
                    <table-col break-point="lg">{{ $t('Joined') }}</table-col>
                    <table-col break-point="lg">{{ $t('Last Login') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="advisor in advisors" :key="advisor.id">
                    <table-col has-check-box>
                      <b-check-box :id="'user'+advisor.id" v-model="advisor.selected" :key="advisor.id"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">{{ advisor.id }}</table-col>
                    <table-col>
                      <user-small-card :user-name="advisor.name" :user-email="advisor.email" :image="advisor.dp" :initials="advisor.initials" :is-online="advisor.is_online">
                        <template v-slot:more_info>
                          <div class="mt-n1 lh-n medium">
                            {{ advisor.company }}
                          </div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="md">
                      <div class="d-flex align-items-center">
                            <span class="mr-1" :class="[advisor.active_status == 0 ? 'text-danger' : ( advisor.active_status == 1 ? 'text-success' : 'text-warning' )]">
                            {{ $t(advisor.active_status == 0 ? 'Inactive' : ( advisor.active_status == 1 ? 'Active' : 'On hold' ) ) }}
                            </span>
                        <a href="javascript:;" class="lh-0" v-on:click="changeAdvisorActiveStatus(advisor)">
                          <nio-icon icon="ni-edit"></nio-icon>
                        </a>
                      </div>
                    </table-col>
                    <table-col break-point="md">
                      <a href="javascript:;" v-on:click="F_getUserNotes(advisor.user_id)" class="d-flex align-items-center">
                        <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;"></nio-icon>
                        <span class="text-primary">{{advisor.notes_count}}</span>
                      </a>
                    </table-col>
                    <table-col break-point="mb">
                      <span class="tb-lead">{{ advisor.city }}</span>
                    </table-col>
                    <table-col break-point="md">
                      <ul class="list-status">
                        <li :title="$t('Assigned')+' '+$t('this week')"><nio-icon class="text-info" icon="ni-clipboad-check"></nio-icon> <span>{{ advisor.assigned_leads.current_week }}</span></li>
                        <li :title="$t('Available')+' '+$t('this week')"><nio-icon icon="ni-clipboard"></nio-icon> <span>{{ advisor.available_leads }}</span></li>
                        <li :title="$t('Assigned')+' '+$t('last week')"><nio-icon icon="ni-clipboad-check-fill" class="text-primary"></nio-icon> <span>{{ advisor.assigned_leads.last_week }}</span></li>
                      </ul>
                    </table-col>
                    <table-col break-point="lg" class="lh-n">
                      <template v-if="advisor.joined === 'pending'">
                        <div class="text-danger">
                          {{ $t('Not joined') }}
                          <div class="text-muted small" v-if="advisor.last_invitation_time">{{ $t('Last invited')+': '+advisor.last_invitation_time }}</div>
                        </div>
                        <a role="button" class="text-purple d-inline-block medium" v-on:click="F_resendUserInvitationEmail(advisor)">{{ $t('Resend invitation') }}</a>
                      </template>
                      <span v-else>{{ advisor.joined }}</span>
                    </table-col>
                    <table-col break-point="lg">{{ advisor.last_seen }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <template v-if="isDesktop">
                          <table-action icon="ni-list-check"  v-tooltip="$t('Assigned Leads')"
                                        :to="{name: (userRole === 'is-company' ? 'comAdvisorProfile' : 'advisorProfile'), params: {id: advisor.id, tab: 'leads'}}"></table-action>
                          <table-action :icon="advisor.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'" v-on:click="F_changeAccountStatus([advisor])"></table-action>
                        </template>
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')"
                                       :to="{name: (userRole === 'is-company' ? 'comAdvisorProfile' : 'advisorProfile'), params: {id: advisor.id} }"></list-item>
                            <list-item icon="ni-activity-round" :text="$t('Activities')"
                                       :to="{name: (userRole === 'is-company' ? 'comAdvisorProfile' : 'advisorProfile'), params: {id: advisor.id, tab: 'activities'}}"></list-item>
                            <list-item v-if="userRole === 'is-admin'"
                                       icon="ni-opt-alt" :text="'Leads '+$t('Limit')" :to="{name: 'advisorProfile', params: {id: advisor.id, tab: 'e_curring'}}"></list-item>
                            <list-item v-if="!isDesktop"
                                       :icon="advisor.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'"
                                       :text="advisor.user_status === 0 ? $t('Activate'): $t('Suspend')"
                                       v-on:click="F_changeAccountStatus([advisor])"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="userNotes.modalOpen"
        :css-class="isDesktop ? 'modal-web': ''"
        @didDismiss="userNotes.modalOpen=false">
      <i-modal
          :title="$t('Advisor')+' '+$t('Notes')"
          @modal-close="userNotes.modalOpen=false">
        <div class="lead_note_single" v-for="(note, index) in userNotes.records" :key="'ln'+index">
          <div>{{note.note}}</div>
          <div class="small mt-1 nk-text-dark">{{note.created_at}}, {{$t('added by')+' '+note.added_by}}</div>
        </div>
        <div class="text-muted" v-if="!userNotes.records.length">{{$t('No notes found.')}}</div>
        <template v-slot:footer>
          <nk-button type="primary" size="sm" v-on:click="[addEditNote.modalOpen=true, Object.assign(addEditNote.note, {id: 0,note: '',user_id: userNotes.user_id})]">
            <nio-icon icon="ni ni-plus"></nio-icon> {{$t('Add Note')}}
          </nk-button>
        </template>
      </i-modal>
    </ion-modal>
    <ion-modal
        :is-open="addEditNote.modalOpen"
        :css-class="isDesktop ? 'modal-web': ''"
        @didDismiss="addEditNote.modalOpen=false">
      <i-modal
          :title="$t('Add Note')"
          @modal-close="addEditNote.modalOpen=false">
        <textarea class="form-control" v-model="addEditNote.note.note"></textarea>
        <template v-slot:footer>
          <nk-button type="primary" size="sm" v-on:click="F_addUserNote"><nio-icon icon="ni ni-plus"></nio-icon> {{$t('Add Note')}}</nk-button>
        </template>
      </i-modal>
    </ion-modal>
    <!-- advisor adding modal -->
    <ion-modal
        :is-open="advisorAddEditForm.modalOpen"
        @didDismiss="advisorAddEditForm.modalOpen=false"
        :css-class="isDesktop ? 'modal-web': ''">
      <add-advisor-modal @modal-close="advisorAddEditForm.modalOpen=false" @success="onAddEditAdvisor"></add-advisor-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {
  IonModal,
  IonSpinner,
  loadingController,
  toastController,
  isPlatform,
} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import TableAction from "@core/components/special-table/components/TableAction";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";

import {useRouter} from "vue-router"
import useAdvisors from "./useAdvisors";
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import useUser from "@core/comp-functions/User"
import storeHelper from '@/store/helper'
import BlockTools from "@core/layouts/main-block/components/BlockTools";
import AddAdvisorModal from "@/views/admin/advisors/components/AddAdvisorModal";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    AddAdvisorModal,
    BlockTools,
    IModal,
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonModal,
    IonSpinner,
    Pagination,
  },
  setup() {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const router = useRouter()
    const{ addUserNote, getUserNotes, updateActiveStatus } = useAdvisors()
    const{changeAccountStatus, deleteUsers, resendInvitationEmail} = useUser()
    const{endPointByRole, userRole} = storeHelper()

    let activeStatusFilter  = ref('')
    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let advisors     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    let userNotes = reactive({
      modalOpen: false,
      total_records: 0,
      records: [],
      user_id: 0,
    });
    let addEditNote = reactive({
      modalOpen: false,
      note:{
        id: 0,
        note: '',
        user_id: 0,
      }
    });
    const paginationCallback = async () => {
      await getAdvisors(true)
    }

    let advisorAddEditForm = reactive({
      modalOpen: false,
    });

    let cancelToken = false
    const getAdvisors = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post(endPointByRole+'/advisors/list', {
            page: pagination.page,
            active_status: activeStatusFilter.value,
            status: statusFilter.value,
            search: searchFilter.value,
            mandate_filter: mandateFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              advisors.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              advisors.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getAdvisors(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onMounted(()=> getAdvisors())

    watch(selectAll, (n) => {
      for(let x in advisors.value){
        advisors.value[x].selected = n
      }
    })

    const F_changeAccountStatus = ( _advisors, action ) => {
      changeAccountStatus(_advisors, 'advisor', action)
      .then(r=>{
        for(let y in advisors.value){
          if(r.ids.includes(advisors.value[y].user_id)){
            advisors.value[y].user_status = r.status
          }
        }
      })
      .catch(()=>{})
    }
    const F_getUserNotes = async (user_id)=>{
      const l = await loadingController.create({message: i18n.t('Please wait...')});
      await l.present()
      getUserNotes(user_id).then((r)=>{
        userNotes.user_id = user_id
        userNotes.total_records = r.total_records
        userNotes.records = r.records
        userNotes.modalOpen = true;
      })
      .catch((m)=>{
        toastController.create({header: i18n.t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      })
      .then(()=> l.dismiss())
    }
    const F_addUserNote = async ()=>{
      const l = await loadingController.create({message: i18n.t('Please wait...')});
      await l.present()
      addUserNote(addEditNote.note).then((r)=>{
        if(parseInt(addEditNote.note.id) < 1){
          userNotes.records.unshift(r)
        }
        else{
          for(let x in userNotes.records){
            if(parseInt(userNotes.records[x].id) === parseInt(addEditNote.note.id)){
              userNotes.records[x].note = addEditNote.note.note
              break;
            }
          }
        }
        addEditNote.modalOpen = false
        for(let x in advisors.value){
          if(parseInt(advisors.value[x].user_id) === parseInt(addEditNote.note.user_id)){
            advisors.value[x].notes_count++;
            break;
          }
        }
      })
      .catch((m)=>{
        toastController.create({header: i18n.t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      })
      .then(()=> l.dismiss())
    }
    const deleteAdvisors = async ( _advisors ) => {
      deleteUsers(_advisors, 'advisor')
      .then(ids=>{
        // Remove the deleted entries from the advisors array
        for(let x in ids){
          for(let y in advisors.value){
            if(advisors.value[y].user_id === ids[x]){
              advisors.value.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the advisor array? and previous pages exists
        if( !advisors.value.length && pagination.page > 1 ){
          // Deduct from total records value
          pagination.totalRecords -= ids.length
          // Goto previous page
          pagination.page--
          getAdvisors(true)
        }
      })
      .catch(()=>{})
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedAdvisors = []
      for(let x in advisors.value){
        if( advisors.value[x].selected){
          selectedAdvisors.push( advisors.value[x])
        }
      }
      if( selectedAdvisors.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'email' ){
        // TODO
        return false
      }
      else if( bulkSelectedAction.value === 'suspend' ){
        await F_changeAccountStatus(selectedAdvisors, 'suspend')
      }
      else{
        // Delete
        await deleteAdvisors(selectedAdvisors)
      }
    }

    const changeAdvisorActiveStatus = (advisor) => {
      updateActiveStatus(advisor)
      .then((new_status)=>{
        advisor.active_status = new_status
      })
      .catch(()=>{})
    }

    const onAddEditAdvisor = (d) => {
      if(d.action === 'added'){
        advisors.value.unshift(d.data)
      }
      else{
        for(let x in advisors.value){
          if(parseInt(advisors.value[x].id) === parseInt(d.data.id)){
            advisors.value[x] = d.data
          }
        }
      }
    }

    const F_resendUserInvitationEmail = (advisor) => {
      resendInvitationEmail(advisor.user_id).then(()=>{
        advisor.last_invitation_time = 'today'
      }).catch(()=>{})
    }

    return {
      activeStatusFilter,
      addEditNote,
      advisorAddEditForm,
      advisors,
      changeAdvisorActiveStatus,
      getAdvisors,
      isDesktop,
      loadingData,
      searchAdvisors,

      mandateFilter,
      searchFilter,
      statusFilter,
      onAddEditAdvisor,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      F_addUserNote,
      F_changeAccountStatus,
      F_getUserNotes,
      F_resendUserInvitationEmail,

      bulkSelectedAction,
      bulkAction,
      router,
      userNotes,
      userRole,
    }
  }
});

</script>
