import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'

export default () => {
    const {endPointByRole} = useStoreHelper()
    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    let getAdvisorsCancelToken = false
    const getAdvisors = (params) => {
        if(false !== getAdvisorsCancelToken){
            getAdvisorsCancelToken()
            getAdvisorsCancelToken = false
        }
        return new Promise((resolve, reject) => {
            axios.post(endPointByRole+'/advisors/list', params,
                {
                    cancelToken: new axios.CancelToken( c => {
                        getAdvisorsCancelToken = c
                    })
                })
                .then( (resp) => {
                    if(!resp.data.success){
                        reject(resp.data.message)
                    }
                    else{
                        for(let x in resp.data.data){
                            resp.data.data[x].selected = false;
                        }
                        resolve(resp.data)
                    }
                })
                .catch( err => {
                    reject(err.response ? err.response.status+' '+err.response.statusText : i18n.t('errors.general_error'))
                })
                .then(() => {
                    getAdvisorsCancelToken = false
                })
        });
    }
    const getUserNotes = async (user_id)=> {
        return new Promise((resolve, reject)=>{
            axios.post(endPointByRole+'/advisors/get_notes',{user_id: user_id})
                .then(r=>{
                    if(!r.data.success){
                        reject(r.data.message)
                    }
                    else{
                        resolve(r.data.data)
                    }
                })
                .catch(er=>{
                    reject(er.response ? er.response.status+' '+er.response.statusText : i18n.t('errors.general_error'))
                });
        });
    }
    const addUserNote = async (noteObject)=> {
        return new Promise((resolve, reject)=>{
            axios.post(endPointByRole+'/advisors/set_note',noteObject)
                .then(r=>{
                    if(!r.data.success){
                        reject(r.data.message)
                    }
                    else{
                        resolve(r.data.data)
                    }
                })
                .catch(er=>{
                    reject(er.response ? er.response.status+' '+er.response.statusText : i18n.t('errors.general_error'))
                });
        });
    }
    const deleteNote = async (id)=> {
        return new Promise((resolve, reject)=>{
            alertController.create({
                header: i18n.t('Delete Note'),
                cssClass: isDesktop ? 'alert-web' : '',
                message: i18n.t('action_confirmation', {action: i18n.t('delete'), type: i18n.t('note')}),
                buttons: [
                    {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-danger'  : '',
                        text: i18n.t('Confirm'), handler: async () => {
                            let loading = await loadingController.create({message: i18n.t('Please wait...')})
                            await loading.present()
                            axios.post(endPointByRole+'/advisors/delete_note', {id: id})
                                .then(r=>{
                                    if(!r.data.success){
                                        reject(r.data.message)
                                    }
                                    else{
                                        resolve()
                                    }
                                })
                                .catch(er=>{
                                    reject(er.response ? er.response.status+' '+er.response.statusText : i18n.t('errors.general_error'))
                                })
                                .then(()=> loading.dismiss())
                        }
                    },
                ]
            }).then((a)=> a.present())
        });
    }
    const updateActiveStatus = (advisor) => {
        return new Promise( (resolve, reject)=>{
            alertController.create({
                header: i18n.t('Update user active status'),
                cssClass: isDesktop ? 'alert-web' : '',
                message: i18n.t('Choose an option'),
                inputs: [
                    {
                        name: 'adv_active_status',
                        type: 'radio',
                        label: i18n.t('Active'),
                        value: 1,
                        checked: advisor.active_status === 1,
                    },
                    {
                        name: 'adv_active_status',
                        type: 'radio',
                        label: i18n.t('Inactive'),
                        value: 0,
                        checked: advisor.active_status === 0,
                    },
                    {
                        name: 'adv_active_status',
                        type: 'radio',
                        label: i18n.t('On hold'),
                        value: 2,
                        checked: advisor.active_status === 2,
                        cssClass: isDesktop ? 'mb-4' : '',
                    }
                ],
                buttons: [
                    {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                        handler: () => reject()
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-primary'  : '',
                        text: i18n.t('Update'), handler: (val) => {
                            if(undefined === val){
                                toastController.create({message: i18n.t('Choose an option'), color: 'danger', position: 'top', duration: 2500}).then((t) => t.present())
                                return false
                            }
                            let loading
                            loadingController.create({message: i18n.t('Please wait...')}).then( (l) => {
                                loading = l
                                l.present()
                            })
                            axios.post(endPointByRole+'/advisors/'+advisor.user_id+'/update', {active_status: val})
                                .then(r=>{
                                    if(!r.data.success){
                                        reject(r.data.message)
                                    }
                                    else{
                                        resolve(val)
                                    }
                                })
                                .catch(er=>{
                                    const msg = er.response ? er.response.status+' '+er.response.statusText : i18n.t('errors.general_error')
                                    toastController.create({header: i18n.t('Error'), message: msg, position: 'top', duration: 3500}).then((t) => t.present())
                                    reject()
                                })
                                .then(()=> loading.dismiss())
                        }
                    },
                ]
            }).then((a)=> a.present())
        })
    }
    return{
        addUserNote,
        deleteNote,
        getAdvisors,
        getUserNotes,
        updateActiveStatus,
    }
}