<template>
  <div class="form-control-wrap">
    <div class="form-icon form-icon-right">
      <nio-icon icon="ni-search"></nio-icon>
    </div>
    <input type="text" class="form-control" id="default-04" :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    modelValue: null,
  },
}
</script>
