<template>
  <div class="nk-block-head nk-block-head-sm">
    <div class="nk-block-between">
      <div class="nk-block-head-content">
        <div class="nk-block-head-sub" v-if="backButton">
          <a href="javascript:;" v-on:click="$router.go(-1)" class="back-to">
            <nio-icon icon="ni-arrow-left"></nio-icon>
            <span>Back</span>
          </a>
        </div>
        <h3 class="nk-block-title page-title text-capitalize">{{ title }}
          <slot name="title_extras"></slot>
        </h3>
        <div class="nk-block-des text-soft">
          <p v-if="description && description.length">{{ description }}</p>
          <slot name="description_extras"></slot>
        </div>
      </div><!-- .nk-block-head-content -->
      <div class="nk-block-head-content">
        <slot></slot>
      </div><!-- .nk-block-head-content -->
    </div><!-- .nk-block-between -->
  </div><!-- .nk-block-head -->
</template>

<script>
export default {
  props:{
    title:{
      required: true,
      type: String
    },
    description: {
      type: String
    },
    backButton: Boolean,
  },
}
</script>
