<template>
  <ion-header>
    <ion-toolbar :style="toolbarStyle">
      <ion-title :style="{'--color': isDesktop && toolbarColor ? 'var(--'+toolbarColor+')': ''}">{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button v-if="!isDesktop" @click="modalClose()" :style="{'--color': undefined !== toolbarColor ? 'white' : ''}">Close</ion-button>
        <a v-else href="javascript:;" class="close text-secondary" @click="modalClose()"><nio-icon icon="ni-cross-sm"></nio-icon></a>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" :style="{background: contentBgColor}">
    <slot></slot>
  </ion-content>
  <ion-footer v-if="hasFooter" class="ion-padding">
    <slot name="footer"></slot>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  isPlatform,
  modalController
} from '@ionic/vue';
import {defineComponent, reactive, ref} from 'vue';

export default defineComponent({
  name: 'IModal',
  props: {
    title: { type: String, default: '' },
    toolbarColor: undefined,
    contentBgColor: {
      type: String,
      default: 'var(--background);'
    },
  },
  components: { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonButtons, IonButton },
  emits: ['modalClose'],
  setup(props, {emit, slots}){

    const isDesktop = isPlatform('desktop')
    const modalClose = async ()=>{
      await modalController.dismiss()
      emit('modalClose', true)
    }
    const toolbarStyle = reactive({'--background': '', '--color': ''})
    if( undefined !== props.toolbarColor && !isDesktop){
      toolbarStyle['--background'] = `var(--${props.toolbarColor})`
      toolbarStyle['--color'] = 'var(--white)'
    }

    let hasFooter = ref(false)
    hasFooter.value = slots.footer && slots.footer().length

    return {
      isDesktop,
      modalClose,
      toolbarStyle,
      hasFooter,
    }
  }
});
</script>
