<template>
  <div class="dropdown">
    <a href="javascript:;" class="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown" :class="btnClassesList">
      <nio-icon icon="ni-more-h"></nio-icon>
    </a>
    <div class="dropdown-menu" :class="'dropdown-menu-'+position">
      <ul class="link-list-opt no-bdr">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    position: {
      type: String,
      default: 'right',
    },
    btnSize: null,
  },
  setup(props){
    let btnClassesList = []
    if(props.btnSize){
      btnClassesList.push(`btn-${props.btnSize}`)
    }
    return {
      btnClassesList,
    }
  }
}
</script>
